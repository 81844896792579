import React, { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import TaskItem from "./TaskItem";
import { useMutation, useQueryClient } from "react-query";
import { useForm } from "react-hook-form"; // For form management
import CreateTaskForm from "./TaskForm";
import ChatModal from "./ChatModal"; // Import the ChatModal component
import { FiX, FiUser, FiSend, FiStar } from "react-icons/fi"; // Import the star icon for new tasks
import formatTime from "../DateFormatter";

const TaskList = () => {
  const [tasks, setTasks] = useState([]);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false); // Modal visibility state for creating tasks
  const [isChatModalOpen, setIsChatModalOpen] = useState(false); // Modal visibility state for chat
  const [selectedTask, setSelectedTask] = useState(null); // State to track the selected task for chat
  const [status, setStatus] = useState(""); // Status to update
  const [isModalOpen, setIsModalOpen] = useState(false); // Control modal visibility
  const [chatMessages, setChatMessages] = useState([]); // Chat messages state
  const [newMessage, setNewMessage] = useState(""); // New chat message to send
  const [filter, setFilter] = useState("All"); // State for filtering tasks
  const [priority, setPriority] = useState("All"); // State for filtering tasks
  // Get token from Redux store
  const token = useSelector((state) => state.auth.token);
  const queryClient = useQueryClient();
  const user = JSON.parse(localStorage.getItem("user"));

  // Fetch tasks
  useEffect(() => {
    const fetchTasks = async () => {
      if (!token) return;

      try {
        const response = await axios.get(
          "http://api.srssoftwares.in/task/api/tasks",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setTasks(response.data);
      } catch (error) {
        console.error("Failed to fetch tasks:", error);
      }
    };

    fetchTasks();
  }, [isCreateModalOpen]);

  // React Hook Form
  const { register, handleSubmit, reset } = useForm();

  // React Query Mutation for creating a task
  const mutation = useMutation(
    async (newTask) => {
      const response = await axios.post(
        "http://api.srssoftwares.in/task/api/tasks",
        newTask,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    },
    {
      onSuccess: () => {
        // Invalidate and refetch tasks
        queryClient.invalidateQueries("tasks");
        setIsCreateModalOpen(false); // Close the modal after successful creation
        reset(); // Reset the form
      },
    }
  );

  // Handle form submission
  const onSubmit = (data) => {
    mutation.mutate(data); // Call the mutation to create a new task
  };

  const handleTaskClick = (task) => {
    console.log("run");
    setSelectedTask(task); // Set the selected task for chat
    setIsChatModalOpen(true); // Open the chat modal
    fetchChatMessages(task._id); // Fetch chat messages
    setIsModalOpen(true);
    setStatus(task.status); // Set initial status
  };

  const fetchChatMessages = async (taskId) => {
    if (!token) return;

    try {
      const response = await axios.post(
        `http://api.srssoftwares.in/task/api/tasks/all-messages`,
        {
          taskId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setChatMessages(response.data);
    } catch (error) {
      console.error("Failed to fetch chat messages:", error);
    }
  };

  // Handle status update submission
  const handleStatusUpdate = async () => {
    if (!selectedTask || !token) return;

    try {
      await axios.put(
        `http://api.srssoftwares.in/task/api/tasks/verify-task`,
        {
          taskId: selectedTask._id, // Pass task ID here
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // Update the task locally
      setTasks((prevTasks) =>
        prevTasks.map((task) =>
          task._id === selectedTask._id ? { ...task, status } : task
        )
      );
      setIsModalOpen(false); // Close the modal
    } catch (error) {
      console.error("Failed to update task status:", error);
    }
  };

  // Handle sending a new chat message
  const handleSendMessage = async () => {
    if (!selectedTask || !newMessage || !token) return;

    try {
      // Sending message to the API
      const response = await axios.post(
        `http://api.srssoftwares.in/task/api/tasks/chat`,
        {
          taskId: selectedTask._id, // Pass task ID here
          message: newMessage, // Pass the new message to be sent
        },
        {
          headers: {
            Authorization: `Bearer ${token}`, // Add the token for authorization
          },
        }
      );

      // After successfully sending the message, fetch updated chat
      fetchChatMessages(selectedTask._id);
      setNewMessage(""); // Clear the input field
    } catch (error) {
      console.error("Failed to send message:", error);
    }
  };

  return (
    <div className='task-list'>
      <div className='flex justify-between items-center mb-4'>
        <h2 className='text-xl font-bold mb-5'>All Tasks</h2>
        <button
          className='px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600'
          onClick={() => setIsCreateModalOpen(true)} // Open the create task modal
        >
          Create New Task
        </button>
      </div>

      {/* Task cards */}
      <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4'>
        {tasks
          .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)) // Sort tasks by creation date in descending order
          .map((task) => {
            const taskStatus = task.status;
            const cardBgColor =
              taskStatus === "Completed"
                ? "bg-green-600" // Green for Completed tasks
                : taskStatus === "Verified"
                ? "bg-orange-600" // Red for Verified tasks
                : "bg-white"; // White for tasks with no specific status
            return (
              <div
                key={task._id}
                className={`${cardBgColor} shadow-md rounded-lg p-4 cursor-pointer`}
                onClick={() => handleTaskClick(task)}
              >
                <TaskItem task={task} /> {/* Add click handler */}
              </div>
            );
          })}
      </div>

      {/* Modal for creating a new task */}
      {isCreateModalOpen && (
        <div className='fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50'>
          <div className='bg-white p-6 rounded-lg shadow-lg w-full max-w-md'>
            <CreateTaskForm setIsModalOpen={setIsCreateModalOpen} />
          </div>
        </div>
      )}

      {isModalOpen && (
        <div className='fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center'>
          <div className='bg-white p-6 rounded-lg w-full mx-2 md:w-[80%] md:mx-[30%] relative'>
            <FiX
              className='absolute top-4 right-4 cursor-pointer text-black'
              size={24}
              onClick={() => setIsModalOpen(false)}
            />
            <h3 className='text-lg font-bold mb-4 text-center'>Verify Task</h3>

            <p className='mb-2'>
              Title: <strong>{selectedTask.title}</strong>
            </p>
            <p className='mb-4'>
              Description: <strong>{selectedTask.description}</strong>
            </p>

            {/* Conditionally render the status dropdown and update button */}
            <div className='mb-4'>
              <label htmlFor='status' className='block text-sm font-medium'>
                Status
              </label>
              <select
                id='status'
                value={status}
                onChange={(e) => setStatus(e.target.value)}
                className='mt-1 block w-full rounded-md border-gray-300 shadow-sm'
              >
                <option value='All'></option>
                <option value='Verify'>Verify</option>
              </select>
            </div>

            {/* Chat Section */}
            <div className='mt-6'>
              <h3 className='text-lg font-bold mb-4'>Chat with Manager</h3>

              {/* Chat Box */}
              <div className='h-[250px] overflow-y-auto mb-4 bg-gray-200 p-3 rounded-md'>
                {chatMessages.map((msg, index) => (
                  <div
                    key={index}
                    className={`flex mb-2 ${
                      msg.sentBy.email === user.email
                        ? "justify-end"
                        : "justify-start"
                    }`}
                  >
                    {msg.sentBy.email !== user.email && (
                      <div className='bg-blue-500 text-white rounded-md p-2'>
                        {msg.message} <br />
                        <p className='text-right text-xs'>
                          {formatTime(msg.sentAt)}
                        </p>
                      </div>
                    )}
                    {msg.sentBy.email === user.email && (
                      <div className='bg-green-500 text-white rounded-md p-2'>
                        {msg.message} <br />
                        <p className='text-right text-xs'>
                          {formatTime(msg.sentAt)}
                        </p>
                      </div>
                    )}
                  </div>
                ))}
              </div>

              {/* New Message Input */}
              <div className='flex'>
                <input
                  type='text'
                  value={newMessage}
                  onChange={(e) => setNewMessage(e.target.value)}
                  className='border rounded-md p-2 w-full'
                  placeholder='Type your message...'
                />
                <button
                  onClick={handleSendMessage}
                  className='ml-2 bg-blue-500 text-white rounded-md p-2'
                >
                  Send
                </button>
              </div>
            </div>

            <button
              onClick={handleStatusUpdate}
              className='mt-4 bg-blue-500 text-white rounded-md p-2 w-full'
            >
              Update Status
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default TaskList;
