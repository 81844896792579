// src/components/TaskItem.js
import React from "react";
import { useSelector } from "react-redux";

const TaskItem = ({ task }) => {
  const getPriorityColor = (priority) => {
    switch (priority) {
      case "High":
        return "bg-red-500";
      case "Medium":
        return "bg-yellow-500";
      case "Low":
        return "bg-green-500";
      default:
        return "bg-gray-500";
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "Verified":
        return "bg-orange-500";
      case "Completed":
        return "bg-green-400";
      case "In Progress":
        return "bg-yellow-400";
      case "Pending":
        return "bg-red-400";
      default:
        return "bg-gray-400";
    }
  };
  const user = useSelector((state) => state.auth.user);

  function formatDate(dateString) {
    const options = { year: "numeric", month: "long", day: "numeric" };
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", options);
  }

  return (
    <div className='p-4 border rounded-lg shadow-md hover:shadow-2xl hover:scale-95 duration-700 bg-white'>
      <h3 className='text-lg font-semibold mb-2'>{task.title}</h3>
      <p className='text-gray-600 mb-2'>{task.description}</p>
      <div className='flex justify-between items-center mb-2'>
        <div className='flex items-center'>
          <span
            className={`inline-block w-3 h-3 rounded-full ${getPriorityColor(
              task.priority
            )}`}
            title={task.priority}
          ></span>
          <span className='ml-2 text-sm'>{task.priority}</span>
        </div>
        <div className='flex items-center'>
          <span
            className={`inline-block w-3 h-3 rounded-full ${getStatusColor(
              task.status
            )}`}
            title={task.status}
          ></span>
          <span className='ml-2 text-sm'>{task.status}</span>
        </div>
      </div>
      <div className='text-gray-600'>
        <p>
          <strong>Created At:</strong> {formatDate(task.createdAt)}
        </p>
        <p>
          <strong>Assigned To:</strong> {task.assignedTo.email}
        </p>
        <p>
          <strong>Deadline:</strong> {formatDate(task.deadline)}
        </p>
      </div>
      <div className='text-gray-600'></div>
    </div>
  );
};

export default TaskItem;
