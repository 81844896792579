import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Bar, Pie, Line } from "react-chartjs-2";
import { BiTask, BiCheckCircle, BiAlarm, BiHourglass } from "react-icons/bi";
import { MdOutlineVerified, MdPendingActions } from "react-icons/md";
import { GiProgression } from "react-icons/gi";
import { GrCompliance } from "react-icons/gr";

import {
  Chart,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  PointElement,
  LineElement,
} from "chart.js";
import axios from "axios";

// Registering the scales and elements
Chart.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  PointElement,
  LineElement
);

const DashboardHome = () => {
  const [tasks, setTasks] = useState([]);
  const [totalTasks, setTotalTasks] = useState(0);
  const [completedTasks, setCompletedTasks] = useState(0);
  const [pendingTasks, setPendingTasks] = useState(0);
  const [inProgressTasks, setInProgressTasks] = useState(0);
  const [verifiedTasks, setVerifiedTasks] = useState(0);
  const user = JSON.parse(localStorage.getItem("user"));
  const userRole = user?.role;
  const token = useSelector((state) => state.auth.token);
  const email = user?.email;

  useEffect(() => {
    const fetchTasks = async () => {
      if (!token) return;
      try {
        let response;

        if (userRole === "User") {
          // API call for User role
          response = await axios.post(
            "http://api.srssoftwares.in/task/api/tasks/get-user-tasks",
            { email },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
        } else if (userRole === "Manager") {
          // API call for Admin role
          response = await axios.get(
            "http://api.srssoftwares.in/task/api/tasks",
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
        }
        if (!response || !response.data) {
          throw new Error("No data received from server");
        }
        const fetchedTasks = response.data;

        // Process task data
        setTasks(fetchedTasks);

        // Calculate task stats
        const total = fetchedTasks.length;
        const completed = fetchedTasks.filter(
          (task) => task.status === "Completed"
        ).length;
        const pending = fetchedTasks.filter(
          (task) => task.status === "Pending"
        ).length;
        const inProgress = fetchedTasks.filter(
          (task) => task.status === "In Progress"
        ).length;
        const isVerified = fetchedTasks.filter(
          (task) => task.status === "Verified"
        ).length;

        // Update state with calculated stats
        setTotalTasks(total);
        setCompletedTasks(completed);
        setPendingTasks(pending);
        setInProgressTasks(inProgress);
        setVerifiedTasks(isVerified);
      } catch (error) {
        console.error("Failed to fetch tasks:", error);
      }
    };

    fetchTasks();
  }, []);

  // Sample data for charts (can update dynamically based on tasks)
  const taskData = {
    labels: ["Pending", "In Progress", "Completed", "Verified"],
    datasets: [
      {
        label: "Task Status",
        data: [pendingTasks, inProgressTasks, completedTasks, verifiedTasks],
        backgroundColor: ["#4CAF50", "#FF9800", "#03A9F4"],
      },
    ],
  };

  return (
    <div className=''>
      {/* Dynamic Cards */}

      {/* Role-Based Content */}
      {userRole === "Manager" ? (
        <div className=''>
          {/* Add manager-specific content here */}
          <div className='grid grid-cols-2 md:grid-cols-5 gap-4 mb-6  cursor-pointer'>
            <div className='bg-white p-4 rounded-lg shadow-md flex items-center hover:scale-105 duration-700'>
              <BiTask className='text-4xl text-blue-500 mr-4' />
              <div>
                <h2 className='text-md font-semibold'>Total Tasks</h2>
                <p className='text-2xl'>{totalTasks}</p>
              </div>
            </div>
            <div className='bg-white p-4 rounded-lg shadow-md flex items-center hover:scale-105 duration-700'>
              <GrCompliance className='text-4xl text-green-500 mr-4' />
              <div>
                <h2 className='text-md font-semibold'>Completed Tasks</h2>
                <p className='text-2xl'>{completedTasks}</p>
              </div>
            </div>
            <div className='bg-white p-4 rounded-lg shadow-md flex items-center hover:scale-105 duration-700'>
              <MdPendingActions className='text-4xl text-orange-500 mr-4' />
              <div>
                <h2 className='text-md font-semibold'>Pending Tasks</h2>
                <p className='text-2xl'>{pendingTasks}</p>
              </div>
            </div>
            <div className='bg-white p-4 rounded-lg shadow-md flex items-center hover:scale-105 duration-700'>
              <GiProgression className='text-4xl text-blue-500 mr-4' />
              <div>
                <h2 className='text-md font-semibold'>In Progress Tasks</h2>
                <p className='text-2xl'>{inProgressTasks}</p>
              </div>
            </div>
            <div className='bg-white p-4 rounded-lg shadow-md flex items-center hover:scale-105 duration-700'>
              <MdOutlineVerified className='text-4xl text-red-500 mr-4' />
              <div>
                <h2 className='text-md font-semibold'>Verified Tasks</h2>
                <p className='text-2xl'>{verifiedTasks}</p>
              </div>
            </div>
          </div>
          <div className='bg-white p-4 rounded-lg shadow-md mb-6'>
            <h2 className='text-md font-semibold mb-4'>Task Overview</h2>
            <Bar data={taskData} options={{ responsive: true }} />
          </div>
        </div>
      ) : (
        <div className=' rounded-lg '>
          <div className='grid grid-cols-2 md:grid-cols-5 gap-4 mb-6  cursor-pointer'>
            <div className='bg-white p-4 rounded-lg shadow-md flex items-center hover:scale-105 duration-700'>
              <BiTask className='text-4xl text-blue-500 mr-4' />
              <div>
                <h2 className='text-md font-semibold'>Total Tasks</h2>
                <p className='text-2xl'>{totalTasks}</p>
              </div>
            </div>
            <div className='bg-white p-4 rounded-lg shadow-md flex items-center hover:scale-105 duration-700'>
              <GrCompliance className='text-4xl text-green-500 mr-4' />
              <div>
                <h2 className='text-md font-semibold'>Completed Tasks</h2>
                <p className='text-2xl'>{completedTasks}</p>
              </div>
            </div>
            <div className='bg-white p-4 rounded-lg shadow-md flex items-center hover:scale-105 duration-700'>
              <MdPendingActions className='text-4xl text-orange-500 mr-4' />
              <div>
                <h2 className='text-md font-semibold'>Pending Tasks</h2>
                <p className='text-2xl'>{pendingTasks}</p>
              </div>
            </div>
            <div className='bg-white p-4 rounded-lg shadow-md flex items-center hover:scale-105 duration-700'>
              <GiProgression className='text-4xl text-blue-500 mr-4' />
              <div>
                <h2 className='text-md font-semibold'>In Progress Tasks</h2>
                <p className='text-2xl'>{inProgressTasks}</p>
              </div>
            </div>
            <div className='bg-white p-4 rounded-lg shadow-md flex items-center hover:scale-105 duration-700'>
              <MdOutlineVerified className='text-4xl text-red-500 mr-4' />
              <div>
                <h2 className='text-md font-semibold'>Verified Tasks</h2>
                <p className='text-2xl'>{verifiedTasks}</p>
              </div>
            </div>
          </div>

          <div className='bg-white p-4 rounded-lg shadow-md mb-6'>
            <h2 className='text-md font-semibold mb-4'>
              Today's Task Overview
            </h2>
            <Line data={taskData} options={{ responsive: true }} />
          </div>
        </div>
      )}
    </div>
  );
};

export default DashboardHome;
