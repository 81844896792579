// src/redux/actions/authActions.js
import { LOGIN_SUCCESS, LOGIN_FAILURE } from "./types";

export const login = (data) => {
  return async (dispatch) => {
    try {
      const response = await fetch(
        "http://api.srssoftwares.in/task/api/auth/login",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Login failed");
      }

      const userData = await response.json();
      dispatch({ type: LOGIN_SUCCESS, payload: userData });
      localStorage.setItem("user", JSON.stringify(userData.user)); // Assuming token is returned in userData
      localStorage.setItem("token", userData.token); // Assuming token is returned in userData

      // Optionally, navigate here if required
    } catch (error) {
      dispatch({ type: LOGIN_FAILURE, payload: error.message });
      // Handle error as needed
    }
  };
};
