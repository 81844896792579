import React, { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { FaUserCircle } from "react-icons/fa"; // Importing an icon for the profile

const TeamOverview = () => {
  const [teamMembers, setTeamMembers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const token = useSelector((state) => state.auth.token);

  useEffect(() => {
    if (!token) return;

    const fetchTeamMembers = async () => {
      try {
        const response = await axios.get(
          "http://api.srssoftwares.in/task/api/users/allUser",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setTeamMembers(response.data);
      } catch (error) {
        setError("Failed to fetch team members");
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchTeamMembers();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }
  console.log(teamMembers);

  return (
    <div className='flex flex-col bg-gray-100 min-h-screen'>
      <h1 className='text-xl font-bold mb-6'>Team Overview</h1>
      <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6'>
        {teamMembers.map((member) => (
          <div key={member._id} className='bg-white p-4 rounded-lg shadow-md'>
            {/* Profile Icon */}
            <div className='flex justify-center mb-4'>
              <FaUserCircle className='text-6xl text-gray-400' />
            </div>
            {/* Member Information */}
            <h2 className='text-xl font-semibold text-center'>
              {member.name || "N/A"}
            </h2>
            <p className='text-gray-600 text-center'>
              Email: {member.email || "N/A"}
            </p>
            <p className='text-gray-600 text-center'>
              Role: {member.role || "N/A"}
            </p>
            <p className='text-gray-600 text-center'>
              Phone: {member.phone || "N/A"}
            </p>
            <div className='mt-2 text-center'>
              <h3 className='font-medium'>Company:</h3>
              <p>{member.company?.name || "N/A"}</p>
              <p>{member.company?.address || "N/A"}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TeamOverview;
