import React from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { FaEnvelope, FaLock, FaUser } from "react-icons/fa"; // React Icons
import { useDispatch } from "react-redux";
import { login } from "../redux/actions/authActions"; // Make sure this is your action creator

const Login = ({ handleLogin }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    await dispatch(login(data)); // Dispatch the login action with form data
    const storedToken = localStorage.getItem("token"); // Get the token
    handleLogin(); // Update the state with new token and role

    // Navigate to the dashboard after login
    if (storedToken) {
      navigate("/dashboard");
    } // You might want to handle this in the action
  };

  return (
    <div
      className='flex justify-center items-center min-h-screen bg-cover bg-center'
      style={{
        backgroundImage:
          "url('https://img.freepik.com/free-photo/blue-smooth-wall-textured-background_53876-106133.jpg?t=st=1727436761~exp=1727440361~hmac=7d16adc8f03830fb40e5854b697bad4b620dd5b7f1623ef7bbfadd4649fbd4b0&w=1060')",
      }} // Add your background image here
    >
      <div className='w-full max-w-md bg-white bg-opacity-80 backdrop-blur-lg p-8 shadow-md rounded-lg animate-fadeIn'>
        <h1 className='text-3xl font-bold mb-6 text-center text-blue-600'>
          Login
        </h1>
        <form onSubmit={handleSubmit(onSubmit)} className='space-y-6'>
          <div>
            <label className='block text-sm font-medium mb-1 text-gray-700'>
              <FaEnvelope className='inline mr-2 text-blue-500' /> Email
            </label>
            <div className='relative'>
              <input
                type='email'
                className='w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-500 transition-transform transform hover:scale-105'
                {...register("email", { required: "Email is required" })}
              />
              {errors.email && (
                <p className='text-red-500 text-sm'>{errors.email.message}</p>
              )}
            </div>
          </div>

          <div>
            <label className='block text-sm font-medium mb-1 text-gray-700'>
              <FaLock className='inline mr-2 text-blue-500' /> Password
            </label>
            <div className='relative'>
              <input
                type='password'
                className='w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-500 transition-transform transform hover:scale-105'
                {...register("password", { required: "Password is required" })}
              />
              {errors.password && (
                <p className='text-red-500 text-sm'>
                  {errors.password.message}
                </p>
              )}
            </div>
          </div>

          <div>
            <label className='block text-sm font-medium mb-1 text-gray-700'>
              <FaUser className='inline mr-2 text-blue-500' /> Role
            </label>
            <div className='relative'>
              <select
                className='w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-500 transition-transform transform hover:scale-105'
                {...register("role", { required: "Role is required" })}
              >
                <option value=''>Select Role</option>
                <option value='Manager'>Manager</option>
                <option value='User'>User</option>
              </select>
              {errors.role && (
                <p className='text-red-500 text-sm'>{errors.role.message}</p>
              )}
            </div>
          </div>

          <button
            type='submit'
            className='w-full bg-blue-500 text-white p-3 rounded-md hover:bg-blue-600 transition duration-200 ease-in-out transform hover:scale-105'
          >
            Login
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;
