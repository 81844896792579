import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";
import NotFound from "./pages/NotFound";
import TaskList from "./components/Task/TaskList";
import MyTasks from "./components/User/MyTasks";
import Profile from "./components/Profile";
import TeamOverview from "./components/Team";
import DashboardHome from "./pages/DashboardHome";
import Cookies from "js-cookie";

const queryClient = new QueryClient();

const App = () => {
  // Create state to track token changes
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [role, setRole] = useState(null);

  console.log(token);
  const handleLogin = () => {
    const token = localStorage.getItem("token");
    Cookies.set("token", token, { expires: 4 / 24 });
    setToken(token);

    const storedUser = JSON.parse(localStorage.getItem("user"));
    console.log(storedUser);
    if (storedUser) {
      setRole(storedUser.role || "User");
    }
  };

  useEffect(() => {
    // Update token and role when component mounts
    handleLogin();
  }, []);
  // const role = "User";

  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <Routes>
          {/* If token exists, redirect to Dashboard, else Login */}
          <Route
            path='/'
            element={
              token ? (
                <Navigate to='/dashboard' />
              ) : (
                <Login handleLogin={handleLogin} />
              )
            }
          />

          {/* Protected Dashboard Route */}
          <Route
            path='/dashboard'
            element={token ? <Dashboard role={role} /> : <Navigate to='/' />}
          >
            <Route path='' element={<DashboardHome />} />
            <Route path='tasks' element={<TaskList />} />
            <Route path='my-tasks' element={<MyTasks />} />
            <Route path='profile' element={<Profile />} />
            <Route path='team-overview' element={<TeamOverview />} />
          </Route>

          {/* 404 Not Found */}
          <Route path='*' element={<NotFound />} />
          <Route path='/login' element={<Login handleLogin={handleLogin} />} />
        </Routes>
      </Router>
    </QueryClientProvider>
  );
};

export default App;
