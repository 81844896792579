// Dashboard.js
import React, { useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import Header from "./Header";
import { Outlet, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const Dashboard = ({ role }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  // const user = {
  //   name: "Aman Sharma",
  //   role: "Manager",
  // };
  const user = useSelector((state) => state.auth.user);
  console.log(user);

  const state = useSelector((state) => state);
  console.log("Current Redux State:", state);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const navigate = useNavigate();

  // useEffect(() => {
  //   const token = localStorage.getItem("token");
  //   if (!token) {
  //     navigate("/"); // Redirect to login if token is not present
  //   }
  // }, []);

  return (
    <div className='flex h-screen'>
      {/* Sidebar */}
      <Sidebar
        role={role}
        isOpen={isSidebarOpen}
        toggleSidebar={toggleSidebar}
      />

      {/* Main Content */}
      <div className='flex-1 flex flex-col'>
        {/* Header */}
        <Header toggleSidebar={toggleSidebar} user={user} />

        {/* Content */}
        <main className='flex-1 p-6 bg-gray-100 overflow-y-auto'>
          <Outlet />
        </main>
      </div>
    </div>
  );
};

export default Dashboard;
