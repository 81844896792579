import React, { useEffect } from "react";
import { useQuery, useMutation } from "react-query";
import axios from "axios";
import { useForm } from "react-hook-form";

const fetchUser = async () => {
  const user = JSON.parse(localStorage.getItem("user"));
  return user;
};

const updateUser = async (userData) => {
  const token = localStorage.getItem("token");
  const response = await axios.put(
    "http://api.srssoftwares.in/task/api/users/userByEmail",
    userData,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  localStorage.setItem("user", JSON.stringify(response.data));
  return response.data;
};

const Profile = () => {
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm();

  // Fetch user data
  const { data: user, isLoading } = useQuery("user", fetchUser, {
    onSuccess: (data) => {
      reset(data); // Reset form with fetched data
    },
  });

  // Update user mutation
  const mutation = useMutation(updateUser, {
    onSuccess: (data) => {
      alert("Profile updated successfully!");
      reset(data);
    },
    onError: (error) => {
      console.error("Error updating profile:", error);
    },
  });

  const onSubmit = (data) => {
    mutation.mutate(data);
  };

  if (isLoading) return <div>Loading...</div>;

  return (
    <div className='flex  '>
      <div className=' rounded-lg   w-full'>
        <h2 className='text-xl font-bold mb-5'>Edit Profile</h2>

        <form onSubmit={handleSubmit(onSubmit)}>
          {/* Name */}
          <div className='mb-4'>
            <label className='block text-sm font-medium mb-1 text-gray-700'>
              Name <span className='text-red-500'>*</span>
            </label>
            <input
              type='text'
              {...register("name", { required: "Name is required" })}
              className={`w-full p-2 border ${
                errors.name ? "border-red-500" : "border-gray-300"
              } rounded-md focus:outline-none focus:ring focus:ring-blue-500`}
            />
            {errors.name && (
              <p className='text-red-500 text-xs mt-1'>{errors.name.message}</p>
            )}
          </div>

          {/* Email */}
          <div className='mb-4'>
            <label className='block text-sm font-medium mb-1 text-gray-700'>
              Email <span className='text-red-500'>*</span>
            </label>
            <input
              type='email'
              {...register("email", { required: "Email is required" })}
              className={`w-full p-2 border ${
                errors.email ? "border-red-500" : "border-gray-300"
              } rounded-md focus:outline-none focus:ring focus:ring-blue-500`}
              readOnly
            />
            {errors.email && (
              <p className='text-red-500 text-xs mt-1'>
                {errors.email.message}
              </p>
            )}
          </div>

          {/* Phone */}
          <div className='mb-4'>
            <label className='block text-sm font-medium mb-1 text-gray-700'>
              Phone
            </label>
            <input
              type='text'
              {...register("phone")}
              className='w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-500'
            />
          </div>

          {/* Company Name */}
          <div className='mb-4'>
            <label className='block text-sm font-medium mb-1 text-gray-700'>
              Company Name
            </label>
            <input
              type='text'
              {...register("company.name")}
              className='w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-500'
            />
          </div>

          {/* Company Address */}
          <div className='mb-4'>
            <label className='block text-sm font-medium mb-1 text-gray-700'>
              Company Address
            </label>
            <input
              type='text'
              {...register("company.address")}
              className='w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-500'
            />
          </div>

          {/* Company Website */}
          <div className='mb-4'>
            <label className='block text-sm font-medium mb-1 text-gray-700'>
              Company Website
            </label>
            <input
              type='text'
              {...register("company.website")}
              className='w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-500'
            />
          </div>

          {/* Department */}
          <div className='mb-4'>
            <label className='block text-sm font-medium mb-1 text-gray-700'>
              Department
            </label>
            <input
              type='text'
              {...register("department")}
              className='w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-500'
            />
          </div>

          <button
            type='submit'
            className='w-full bg-blue-500 text-white p-3 rounded-md hover:bg-blue-600 transition duration-200 ease-in-out'
          >
            Update Profile
          </button>
        </form>
      </div>
    </div>
  );
};

export default Profile;
