import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FaBars, FaBell, FaUserCircle, FaTimes } from "react-icons/fa";
import axios from "axios";

const Header = ({ toggleSidebar }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isNotificationPanelOpen, setIsNotificationPanelOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedNotification, setSelectedNotification] = useState(null);
  const [notifications, setNotifications] = useState([]); // Store notifications

  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("user"));

  const fetchTasksAndUpdateNotifications = async () => {
    try {
      const response = await axios.post(
        "http://api.srssoftwares.in/task/api/tasks/get-user-tasks",
        { email: user.email }, // Assuming you have the user's email stored
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      const tasks = response.data;
      console.log(tasks);
      // Check for new tasks or task updates (e.g., status changed)
      const newNotifications = tasks
        .filter((task) => task.isNew || task.statusChanged) // Adjust the condition based on your API response
        .map((task) => ({
          id: task.id,
          title: "Task Update",
          message: task.isNew
            ? "New task assigned"
            : `Task ${task.status} updated`,
          date: new Date().toLocaleTimeString(),
        }));

      // Add new notifications
      if (newNotifications.length > 0) {
        setNotifications((prevNotifications) => [
          ...newNotifications,
          ...prevNotifications,
        ]);
      }
    } catch (error) {
      console.error("Failed to fetch tasks:", error);
    }
  };

  // Handle opening notification modal
  const openNotificationModal = (notification) => {
    setSelectedNotification(notification);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedNotification(null);
  };

  const handleLogout = () => {
    navigate("/login");
    localStorage.clear();
  };

  const unreadCount = notifications.length;

  return (
    <header className='bg-gray-800 text-white p-4 shadow-md relative'>
      <div className='container mx-auto flex justify-between items-center'>
        <div className='flex items-center'>
          <h1 className='text-xl font-semibold'>SRS SOFTWARES</h1>
        </div>
        <div className='flex items-center space-x-4'>
          <div className='relative'>
            <button
              onClick={() => setIsDropdownOpen(!isDropdownOpen)}
              className='text-white text-2xl focus:outline-none'
            >
              <FaUserCircle />
            </button>
            {isDropdownOpen && (
              <div className='absolute right-0 mt-2 w-48 bg-white text-black rounded shadow-lg py-2 z-10'>
                <p className='px-4 py-2 font-semibold'>{user?.name}</p>
                <p className='px-4 py-2 text-sm text-gray-500'>{user?.role}</p>
                <hr className='my-2' />
                <button
                  className='block w-full text-left px-4 py-2 hover:bg-gray-100'
                  onClick={handleLogout}
                >
                  Logout
                </button>
              </div>
            )}
          </div>
          <button
            onClick={toggleSidebar}
            className='md:hidden text-white text-2xl focus:outline-none'
          >
            <FaBars />
          </button>
        </div>
      </div>

      {isNotificationPanelOpen && (
        <div className='fixed top-0 right-0 w-64 h-screen bg-gray-200 shadow-lg z-50 overflow-y-auto'>
          <div className='p-4 bg-gray-200 text-black font-bold text-lg flex justify-between items-center'>
            Notifications
            <button
              onClick={() => setIsNotificationPanelOpen(false)}
              className='text-black text-xl focus:outline-none'
            >
              <FaTimes />
            </button>
          </div>
          <div className='p-4'>
            {notifications.length > 0 ? (
              notifications.map((notification) => (
                <div
                  key={notification.id}
                  className='mb-4 border-b pb-2 cursor-pointer'
                  onClick={() => openNotificationModal(notification)}
                >
                  <p className='text-gray-700'>{notification.message}</p>
                  <p className='text-sm text-gray-500'>{notification.date}</p>
                </div>
              ))
            ) : (
              <p>No notifications</p>
            )}
          </div>
        </div>
      )}

      {isModalOpen && selectedNotification && (
        <div className='fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50'>
          <div className='bg-white p-6 rounded-lg shadow-lg w-80 relative'>
            <button
              onClick={closeModal}
              className='absolute top-2 right-2 text-gray-600 text-xl'
            >
              <FaTimes />
            </button>
            <h2 className='text-lg text-black font-bold mb-2'>
              {selectedNotification.title}
            </h2>
            <p className='text-gray-700 mb-2'>{selectedNotification.message}</p>
            <p className='text-sm text-gray-500 text-right'>
              {selectedNotification.date}
            </p>
          </div>
        </div>
      )}
    </header>
  );
};

export default Header;
