// Sidebar.js
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FaTasks, FaUser, FaUsers, FaFileAlt, FaHome } from "react-icons/fa"; // Icons for sidebar items
import Logo from "../assets/logo.png";
const Sidebar = ({ role, isOpen, toggleSidebar }) => {
  const managerItems = [
    { name: "Home", path: "/dashboard", icon: <FaHome /> },

    { name: "Manage Tasks", path: "/dashboard/tasks", icon: <FaTasks /> },
    {
      name: "Team Overview",
      path: "/dashboard/team-overview",
      icon: <FaUsers />,
    },
    { name: "Profile", path: "/dashboard/profile", icon: <FaUser /> },
  ];

  const userItems = [
    { name: "Home", path: "/dashboard", icon: <FaHome /> },
    { name: "My Tasks", path: "/dashboard/my-tasks", icon: <FaTasks /> },
    { name: "Profile", path: "/dashboard/profile", icon: <FaUser /> },
  ];

  const menuItems = role === "Manager" ? managerItems : userItems;

  return (
    <aside
      className={`${
        isOpen ? "block" : "hidden"
      } md:block bg-gray-200 w-64 p-5 transition-all duration-300 h-screen md:relative fixed z-40`}
    >
      <Link to='/dashboard'>
        <div className='mb-10' onClick={toggleSidebar}>
          <img src={Logo} alt='Logo' className='h-12 mx-auto mb-4' />
          <h2 className='text-xl font-bold text-center'>{role} Dashboard</h2>
        </div>
      </Link>
      <nav>
        <ul>
          {menuItems.map((item, index) => (
            <li key={index} className='mb-2'>
              <Link
                to={item.path}
                className='flex items-center p-2 hover:bg-gray-400 rounded'
                onClick={toggleSidebar} // Close sidebar when clicking a menu item on mobile
              >
                <span className='mr-3 text-lg'>{item.icon}</span>
                {item.name}
              </Link>
            </li>
          ))}
        </ul>
      </nav>
    </aside>
  );
};

export default Sidebar;
