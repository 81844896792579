import React, { useState, useEffect } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useMutation, useQueryClient } from "react-query";

const CreateTaskForm = ({ setIsModalOpen }) => {
  const { register, handleSubmit, reset } = useForm();
  const [users, setUsers] = useState([]); // State to hold fetched users
  const token = useSelector((state) => state.auth.token); // Get token from Redux store
  const queryClient = useQueryClient();

  // Fetch users for the 'Assigned To' dropdown
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get(
          "http://api.srssoftwares.in/task/api/users/allUser",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setUsers(response.data);
      } catch (error) {
        console.error("Failed to fetch users:", error);
      }
    };

    fetchUsers();
  }, [token]);

  // Mutation for creating a new task
  const mutation = useMutation(
    async (newTask) => {
      const response = await axios.post(
        "http://api.srssoftwares.in/task/api/tasks",
        newTask,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("tasks");
        setIsModalOpen(false);
        reset();
      },
    }
  );

  const onSubmit = (data) => {
    mutation.mutate(data);
  };
  const today = new Date().toISOString().split("T")[0];

  return (
    <div className='bg-white p-6 rounded-lg shadow-lg w-full max-w-md'>
      <h3 className='text-lg font-bold mb-4'>Create New Task</h3>
      <form onSubmit={handleSubmit(onSubmit)}>
        {/* Title Field */}
        <div className='mb-4'>
          <label className='block mb-1'>Title</label>
          <input
            className='w-full border p-2 rounded-md'
            {...register("title", { required: true })}
            placeholder='Task Title'
          />
        </div>

        {/* Description Field */}
        <div className='mb-4'>
          <label className='block mb-1'>Description</label>
          <textarea
            className='w-full border p-2 rounded-md'
            {...register("description", { required: true })}
            placeholder='Task Description'
          />
        </div>

        {/* Priority Field */}
        <div className='mb-4'>
          <label className='block mb-1'>Priority</label>
          <select
            className='w-full border p-2 rounded-md'
            {...register("priority")}
          >
            <option value='Low'>Low</option>
            <option value='Medium'>Medium</option>
            <option value='High'>High</option>
          </select>
        </div>

        {/* Assigned To Dropdown */}
        <div className='mb-4'>
          <label className='block mb-1'>Assigned To</label>
          <select
            className='w-full border p-2 rounded-md'
            {...register("assignedTo", { required: true })}
          >
            <option value=''>Select a User</option>
            {users.map((user) => (
              <option key={user._id} value={user._id}>
                {user.name}
              </option>
            ))}
          </select>
        </div>

        {/* Deadline Field */}
        <div className='mb-4'>
          <label className='block mb-1'>Deadline</label>
          <input
            type='date'
            className='w-full border p-2 rounded-md'
            {...register("deadline", { required: true })}
            min={today} // Set the minimum date to today
          />
        </div>

        {/* Action Buttons */}
        <div className='flex justify-end space-x-2'>
          <button
            type='button'
            className='px-4 py-2 bg-gray-500 text-white rounded-md'
            onClick={() => setIsModalOpen(false)}
          >
            Cancel
          </button>
          <button
            type='submit'
            className='px-4 py-2 bg-blue-500 text-white rounded-md'
          >
            Create Task
          </button>
        </div>
      </form>
    </div>
  );
};

export default CreateTaskForm;
