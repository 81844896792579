import React, { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import TaskItem from "../Task/TaskItem";
import { FiX, FiUser, FiSend, FiStar } from "react-icons/fi"; // Import the star icon for new tasks
import formatTime from "../DateFormatter";

const MyTasks = () => {
  const [tasks, setTasks] = useState([]);
  const [selectedTask, setSelectedTask] = useState(null); // For modal form
  const [status, setStatus] = useState(""); // Status to update
  const [isModalOpen, setIsModalOpen] = useState(false); // Control modal visibility
  const [chatMessages, setChatMessages] = useState([]); // Chat messages state
  const [newMessage, setNewMessage] = useState(""); // New chat message to send
  const [filter, setFilter] = useState("All"); // State for filtering tasks
  const [priority, setPriority] = useState("All"); // State for filtering tasks

  // Get token from Redux store
  const token = useSelector((state) => state.auth.token);
  const user = JSON.parse(localStorage.getItem("user"));
  const email = user.email;

  // Fetch tasks
  useEffect(() => {
    const fetchTasks = async () => {
      if (!token) return;

      try {
        const response = await axios.post(
          "http://api.srssoftwares.in/task/api/tasks/get-user-tasks",
          {
            email,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setTasks(response.data);
      } catch (error) {
        console.error("Failed to fetch tasks:", error);
      }
    };

    fetchTasks();
  }, [token]);

  // Handle task card click
  const handleTaskClick = (task) => {
    setSelectedTask(task);
    setStatus(task.status); // Set initial status
    fetchChatMessages(task._id); // Fetch chat messages
    setIsModalOpen(true);
  };

  // Fetch chat messages for the selected task
  const fetchChatMessages = async (taskId) => {
    if (!token) return;

    try {
      const response = await axios.post(
        `http://api.srssoftwares.in/task/api/tasks/all-messages`,
        {
          taskId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setChatMessages(response.data);
    } catch (error) {
      console.error("Failed to fetch chat messages:", error);
    }
  };

  // Handle status update submission
  const handleStatusUpdate = async () => {
    if (!selectedTask || !token) return;

    try {
      await axios.put(
        `http://api.srssoftwares.in/task/api/tasks/update-task`,
        {
          taskId: selectedTask._id, // Pass task ID here
          status: status,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // Update the task locally
      setTasks((prevTasks) =>
        prevTasks.map((task) =>
          task._id === selectedTask._id ? { ...task, status } : task
        )
      );
      setIsModalOpen(false); // Close the modal
    } catch (error) {
      console.error("Failed to update task status:", error);
    }
  };

  // Handle sending a new chat message
  const handleSendMessage = async () => {
    if (!selectedTask || !newMessage || !token) return;

    try {
      // Sending message to the API
      const response = await axios.post(
        `http://api.srssoftwares.in/task/api/tasks/chat`,
        {
          taskId: selectedTask._id, // Pass task ID here
          message: newMessage, // Pass the new message to be sent
        },
        {
          headers: {
            Authorization: `Bearer ${token}`, // Add the token for authorization
          },
        }
      );

      // After successfully sending the message, fetch updated chat
      fetchChatMessages(selectedTask._id);
      setNewMessage(""); // Clear the input field
    } catch (error) {
      console.error("Failed to send message:", error);
    }
  };

  // Filter tasks based on the selected status
  // Filter tasks based on the selected status and priority
  const filteredTasks = tasks.filter((task) => {
    // Filter by status
    const statusMatch = filter === "All" || task.status === filter;

    // Filter by priority
    const priorityMatch = priority === "All" || task.priority === priority;

    // Return true only if both status and priority match
    return statusMatch && priorityMatch;
  });

  const today = new Date();

  return (
    <div className='task-list'>
      {/* Header and Filters */}
      <div className='flex justify-between items-center mb-5'>
        <h2 className='text-xl font-bold'>All Tasks</h2>

        {/* Filtering Options */}
        <div className='flex space-x-4'>
          <div>
            <label htmlFor='filter' className='block text-sm font-medium'>
              Filter by Status
            </label>
            <select
              id='filter'
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
              className='mt-1 block w-full rounded-md border-gray-300 shadow-sm'
            >
              <option value='All'>All</option>
              <option value='Pending'>Not Started</option>
              <option value='In Progress'>In Progress</option>
              <option value='Completed'>Completed</option>
            </select>
          </div>

          <div>
            <label htmlFor='priority' className='block text-sm font-medium'>
              Filter by Priority
            </label>
            <select
              id='priority'
              value={priority}
              onChange={(e) => setPriority(e.target.value)}
              className='mt-1 block w-full rounded-md border-gray-300 shadow-sm'
            >
              <option value='All'>All</option>
              <option value='Low'>Low</option>
              <option value='Medium'>Medium</option>
              <option value='High'>High</option>
            </select>
          </div>
        </div>
      </div>

      <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-4'>
        {filteredTasks
          .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)) // Sort tasks by creation date in descending order
          .map((task) => {
            // Convert task deadline to a Date object
            const taskDeadline = new Date(task.deadline);
            const taskStatus = task.status;
            console.log(taskStatus);

            // Determine background color based on deadline
            const cardBgColor =
              taskDeadline <= today && taskStatus !== "Completed"
                ? "bg-red-500"
                : "bg-white";

            return (
              <div
                key={task._id}
                className={`${cardBgColor} shadow-md rounded-lg p-4 cursor-pointer`}
                onClick={() => handleTaskClick(task)}
              >
                <TaskItem task={task} />
                {/* Display new task label if task is newly assigned */}
                {task.isNew && (
                  <div className='absolute top-2 right-2 bg-yellow-400 text-white text-xs rounded-full px-2 py-1 flex items-center'>
                    <FiStar className='mr-1' /> New
                  </div>
                )}
              </div>
            );
          })}
      </div>

      {/* Modal for updating task status */}
      {isModalOpen && (
        <div className='fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center'>
          <div className='bg-white p-6 rounded-lg w-full mx-2 md:w-[80%] md:mx-[30%] relative'>
            <FiX
              className='absolute top-4 right-4 cursor-pointer text-black'
              size={24}
              onClick={() => setIsModalOpen(false)}
            />
            <h3 className='text-lg font-bold mb-4'>Update Task Status</h3>

            <p className='mb-4'>
              Task: <strong>{selectedTask.title}</strong>
            </p>

            {/* Conditionally render the status dropdown and update button */}
            <div className='mb-4'>
              <label htmlFor='status' className='block text-sm font-medium'>
                Status
              </label>
              <select
                id='status'
                value={status}
                onChange={(e) => setStatus(e.target.value)}
                className='mt-1 block w-full rounded-md border-gray-300 shadow-sm'
                disabled={selectedTask.status === "Completed"} // Disable if status is 'Completed'
              >
                <option value='Not Started'>Not Started</option>
                <option value='In Progress'>In Progress</option>
                <option value='Completed'>Completed</option>
              </select>
            </div>

            {/* Chat Section */}
            <div className='mt-6'>
              <h3 className='text-lg font-bold mb-4'>Chat with Manager</h3>

              {/* Chat Box */}
              <div className='h-[250px] overflow-y-auto mb-4 bg-gray-200 p-3 rounded-md'>
                {chatMessages.map((msg, index) => (
                  <div
                    key={index}
                    className={`flex mb-2 ${
                      msg.sentBy.email === user.email
                        ? "justify-end"
                        : "justify-start"
                    }`}
                  >
                    {msg.sentBy.email !== user.email && (
                      <div className='bg-blue-500 text-white rounded-md p-2'>
                        {msg.message} <br />
                        <p className='text-right text-xs'>
                          {formatTime(msg.sentAt)}
                        </p>
                      </div>
                    )}
                    {msg.sentBy.email === user.email && (
                      <div className='bg-green-500 text-white rounded-md p-2'>
                        {msg.message} <br />
                        <p className='text-right text-xs'>
                          {formatTime(msg.sentAt)}
                        </p>
                      </div>
                    )}
                  </div>
                ))}
              </div>

              {/* New Message Input */}
              <div className='flex'>
                <input
                  type='text'
                  value={newMessage}
                  onChange={(e) => setNewMessage(e.target.value)}
                  className='border rounded-md p-2 w-full'
                  placeholder='Type your message...'
                />
                <button
                  onClick={handleSendMessage}
                  className='ml-2 bg-blue-500 text-white rounded-md p-2'
                >
                  Send
                </button>
              </div>
            </div>

            {selectedTask.status !== "Completed" && (
              <button
                onClick={handleStatusUpdate}
                className='mt-4 bg-blue-500 text-white rounded-md p-2 w-full'
              >
                Update Status
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default MyTasks;
